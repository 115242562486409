import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  token: null,
  account: null,
  refresh: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuthToken(state, action) {
      state.token = action.payload.access;
    },
    setRefreshToken(state, action) {
      state.refresh = action.payload.refresh; // Correctly update the refresh field
    },
    setAccount(state, action) {
      state.account = action.payload;
    },
    logout(state) {
      state.account = null;
      state.token = null;
      state.refresh = null; // Ensure to clear the refresh token on logout
    },
  },
});

export default authSlice;
