import './Devices.css';
import React from 'react';
import PlusWhiteIcon from "../../assets/images/plus-white.svg"
import ImageIcon from "../../assets/images/image.svg";




function Devices() {
    return (
        <div className='container-padding'>
              <div className='row-sc gap5 pad-b20'>
                <span className='green29'>Dashboard</span>
                <span className='green85'>/</span>
                <span className='green29'>Inventory</span>
                <span className='green85'>/</span>
                <span className='green85'>Devices</span>
            </div>
            <div className='pd-base'>
                <div className='row-sc gap20'>
                    <span className='green29'>Devices</span>

                    <button className='new-plant-button'>
                        <img src={PlusWhiteIcon} />
                        <span className='white'>New Device</span>
                    </button>
                </div>

                <div className='row-sc width100p gap20 wrap'>
                    <div className='devices-filter'>
                        <span className='green29'>Sensors</span>
                    </div>

                    <div className='devices-filter'>
                        <span className='green29'>Acuators</span>
                    </div>

                    <div className='devices-filter'>
                        <span className='green29'>Climate & Environment</span>
                    </div>

                    <div className='devices-filter'>
                        <span className='green29'>Lamps</span>
                    </div>

                    <div className='devices-filter'>
                        <span className='green29'>Pumps</span>
                    </div>
                </div>

                <div className='row-sbc width100p gap20'>
                    <div className='pd-yield-cards'>
                        <div className='grey-icon-back'>
                            <img src={ImageIcon} />
                        </div>

                        <div className='column-cs'>
                            <span className='green29'>Count</span>
                            <span className='green29'>74</span>
                        </div>
                    </div>

                    <div className='pd-yield-cards'>
                        <div className='grey-icon-back'>
                            <img src={ImageIcon} />
                        </div>

                        <div className='column-cs'>
                            <span className='green29'>Cost</span>
                            <span className='green29'>$</span>
                        </div>
                    </div>

                    <div className='pd-yield-cards'>
                        <div className='grey-icon-back'>
                            <img src={ImageIcon} />
                        </div>

                        <div className='column-cs'>
                            <span className='green29'>Avg. Age</span>
                            <span className='green29'>2</span>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default Devices;