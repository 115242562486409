import "./CloneDetails.css";
import React, { useState, useEffect } from "react";
import Textarea from "@mui/joy/Textarea";

import ImageIcon from "../../assets/images/image.svg";
import ArrowLeftIcon from "../../assets/images/arrow-left.svg";
import HandPointrightIcon from "../../assets/images/hand-point-right.svg";
import Modal from "react-modal";
import CloseIcon from "../../assets/images/close.svg";
import EditIcon from "../../assets/images/edit.svg";
import SaveBrightIcon from "../../assets/images/save-bright.svg";
import TextField from "@mui/material/TextField";

function CloneDetails() {
  const [estimatedClicked, setEstimatedClicked] = useState("estimated");
  const [showStrainModal, setShowStrainModal] = useState(false);
  const [editStrainModal, setEditStrainModal] = useState(false);

  return (
    <div className="container-padding">
      <Modal
        isOpen={showStrainModal}
        onRequestClose={() => setShowStrainModal(false)}
        className="strain-modal-base"
      >
        {editStrainModal ? (
          <div className="strain-modal">
            <div className="row-sbc width100p">
              <span className="green29">OG Kush</span>

              <div className="row-sc gap20">
                <div
                  className="row-sc gap10 pointer"
                  onClick={() => setEditStrainModal(!editStrainModal)}
                >
                  <img src={EditIcon} />
                  <span className="green29">Edit</span>
                </div>
                <img
                  src={CloseIcon}
                  className="pointer"
                  onClick={() => setShowStrainModal(!showStrainModal)}
                />
              </div>
            </div>

            <div className="row-sbs width100p gap20">
              <div className="strain-cards">
                <span className="green3D">Type</span>
                <span className="green29">Feminized</span>
              </div>

              <div className="strain-cards">
                <span className="green3D">Supplier</span>
                <span className="green29">Supplier_A</span>
              </div>

              <div className="strain-cards">
                <span className="green3D">Manufacturer</span>
                <span className="green29">Manufacturer_A</span>
              </div>
            </div>

            <div className="row-sbc width100p gap20">
              <div className="pd-detail-cards">
                <div className="row-sbc width100p">
                  <span className="green29">Kind:</span>
                  <span className="green29">Hybrid</span>
                </div>

                <div className="row-sbc width100p">
                  <span className="green29">Genetic Background:</span>
                  <span className="green29">N/A</span>
                </div>

                <div className="row-sbc width100p">
                  <span className="green29">Composition:</span>
                  <span className="green29">N/A</span>
                </div>

                <div className="row-sbc width100p">
                  <span className="green29">Effect:</span>
                  <span className="green29">N/A</span>
                </div>

                <div className="row-sbc width100p">
                  <span className="green29">Climate:</span>
                  <span className="green29">N/A</span>
                </div>

                <div className="row-sbc width100p">
                  <span className="green29">Flavor:</span>
                  <span className="green29">N/A</span>
                </div>

                <div className="row-sbc width100p">
                  <span className="green29">User For:</span>
                  <span className="green29">N/A</span>
                </div>
              </div>

              <div className="pd-detail-cards">
                <div className="row-sbc width100p">
                  <span className="green29">Expected Yield:</span>
                  <span className="green29">100g</span>
                </div>

                <div className="row-sbc width100p">
                  <span className="green29">Expected THC:</span>
                  <span className="green29">20%</span>
                </div>

                <div className="row-sbc width100p">
                  <span className="green29">Expected CBD:</span>
                  <span className="green29">13%</span>
                </div>

                <div className="row-sbc width100p">
                  <span className="green29">Expected Grow Duration:</span>
                  <span className="green29">40 Days</span>
                </div>

                <div className="row-sbc width100p">
                  <span className="green29">Expected Plant Height:</span>
                  <span className="green29">100cm</span>
                </div>

                <div className="row-sbc width100p">
                  <span className="green29">Expected Turpines:</span>
                  <span className="green29">400g</span>
                </div>

                <div className="row-sbc width100p">
                  <span className="green29">Harvesting Month:</span>
                  <span className="green29">September</span>
                </div>
              </div>
            </div>

            <div className="strain-info"></div>
          </div>
        ) : (
          <div className="edit-strain-modal">
            <div className="edit-strain-scroll">
              <div className="row-sbc width100p">
                <div
                  className="row-sc gap10 pointer"
                  onClick={() => setEditStrainModal(!editStrainModal)}
                >
                  <img src={ArrowLeftIcon} />
                  <span className="green29">Edit Strain</span>
                </div>
                <div className="row-sc gap20">
                  <div className="row-sc gap10 pointer">
                    <img src={SaveBrightIcon} />
                    <span className="green29">Save</span>
                  </div>
                  <img src={CloseIcon} />
                </div>
              </div>

              <div className="row-sbc width100p gap20">
                <TextField
                  id="outlined-basic"
                  label="Name"
                  variant="outlined"
                  fullWidth
                  color="success"
                  sx={{
                    color: "#17823B",
                    ".MuiOutlinedInput-notchedOutline": {
                      borderColor: "#17823B",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#17823B",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#17823B !important",
                    },
                  }}
                  InputProps={{
                    style: {
                      borderRadius: "10px",
                      height: 48,
                    },
                  }}
                />

                <TextField
                  id="outlined-basic"
                  label="Type"
                  variant="outlined"
                  fullWidth
                  color="success"
                  sx={{
                    color: "#17823B",
                    ".MuiOutlinedInput-notchedOutline": {
                      borderColor: "#17823B",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#17823B",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#17823B !important",
                    },
                  }}
                  InputProps={{
                    style: {
                      borderRadius: "10px",
                      height: 48,
                    },
                  }}
                />
              </div>

              <div className="row-sbc width100p gap20">
                <TextField
                  id="outlined-basic"
                  label="Supplier"
                  variant="outlined"
                  fullWidth
                  color="success"
                  sx={{
                    color: "#17823B",
                    ".MuiOutlinedInput-notchedOutline": {
                      borderColor: "#17823B",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#17823B",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#17823B !important",
                    },
                  }}
                  InputProps={{
                    style: {
                      borderRadius: "10px",
                      height: 48,
                    },
                  }}
                />

                <TextField
                  id="outlined-basic"
                  label="Manufacturer"
                  variant="outlined"
                  fullWidth
                  color="success"
                  sx={{
                    color: "#17823B",
                    ".MuiOutlinedInput-notchedOutline": {
                      borderColor: "#17823B",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#17823B",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#17823B !important",
                    },
                  }}
                  InputProps={{
                    style: {
                      borderRadius: "10px",
                      height: 48,
                    },
                  }}
                />
              </div>

              <div className="row-sbc width100p gap20">
                <TextField
                  id="outlined-basic"
                  label="Kind"
                  variant="outlined"
                  fullWidth
                  color="success"
                  sx={{
                    color: "#17823B",
                    ".MuiOutlinedInput-notchedOutline": {
                      borderColor: "#17823B",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#17823B",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#17823B !important",
                    },
                  }}
                  InputProps={{
                    style: {
                      borderRadius: "10px",
                      height: 48,
                    },
                  }}
                />

                <TextField
                  id="outlined-basic"
                  label="Genetic Baclground"
                  variant="outlined"
                  fullWidth
                  color="success"
                  sx={{
                    color: "#17823B",
                    ".MuiOutlinedInput-notchedOutline": {
                      borderColor: "#17823B",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#17823B",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#17823B !important",
                    },
                  }}
                  InputProps={{
                    style: {
                      borderRadius: "10px",
                      height: 48,
                    },
                  }}
                />
              </div>

              <div className="row-sbc width100p gap20">
                <TextField
                  id="outlined-basic"
                  label="Composition"
                  variant="outlined"
                  fullWidth
                  color="success"
                  sx={{
                    color: "#17823B",
                    ".MuiOutlinedInput-notchedOutline": {
                      borderColor: "#17823B",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#17823B",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#17823B !important",
                    },
                  }}
                  InputProps={{
                    style: {
                      borderRadius: "10px",
                      height: 48,
                    },
                  }}
                />

                <TextField
                  id="outlined-basic"
                  label="Effect"
                  variant="outlined"
                  fullWidth
                  color="success"
                  sx={{
                    color: "#17823B",
                    ".MuiOutlinedInput-notchedOutline": {
                      borderColor: "#17823B",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#17823B",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#17823B !important",
                    },
                  }}
                  InputProps={{
                    style: {
                      borderRadius: "10px",
                      height: 48,
                    },
                  }}
                />
              </div>

              <div className="row-sbc width100p gap20">
                <TextField
                  id="outlined-basic"
                  label="Climate"
                  variant="outlined"
                  fullWidth
                  color="success"
                  sx={{
                    color: "#17823B",
                    ".MuiOutlinedInput-notchedOutline": {
                      borderColor: "#17823B",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#17823B",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#17823B !important",
                    },
                  }}
                  InputProps={{
                    style: {
                      borderRadius: "10px",
                      height: 48,
                    },
                  }}
                />

                <TextField
                  id="outlined-basic"
                  label="Flavor"
                  variant="outlined"
                  fullWidth
                  color="success"
                  sx={{
                    color: "#17823B",
                    ".MuiOutlinedInput-notchedOutline": {
                      borderColor: "#17823B",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#17823B",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#17823B !important",
                    },
                  }}
                  InputProps={{
                    style: {
                      borderRadius: "10px",
                      height: 48,
                    },
                  }}
                />
              </div>

              <div className="row-sbc width100p gap20">
                <TextField
                  id="outlined-basic"
                  label="used For"
                  variant="outlined"
                  fullWidth
                  color="success"
                  sx={{
                    color: "#17823B",
                    ".MuiOutlinedInput-notchedOutline": {
                      borderColor: "#17823B",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#17823B",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#17823B !important",
                    },
                  }}
                  InputProps={{
                    style: {
                      borderRadius: "10px",
                      height: 48,
                    },
                  }}
                />

                <TextField
                  id="outlined-basic"
                  label="Harvesting Month"
                  variant="outlined"
                  fullWidth
                  color="success"
                  sx={{
                    color: "#17823B",
                    ".MuiOutlinedInput-notchedOutline": {
                      borderColor: "#17823B",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#17823B",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#17823B !important",
                    },
                  }}
                  InputProps={{
                    style: {
                      borderRadius: "10px",
                      height: 48,
                    },
                  }}
                />
              </div>

              <div className="row-sbc width100p gap20">
                <TextField
                  id="outlined-basic"
                  label="Expected Yield"
                  variant="outlined"
                  fullWidth
                  color="success"
                  sx={{
                    color: "#17823B",
                    ".MuiOutlinedInput-notchedOutline": {
                      borderColor: "#17823B",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#17823B",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#17823B !important",
                    },
                  }}
                  InputProps={{
                    style: {
                      borderRadius: "10px",
                      height: 48,
                    },
                  }}
                />

                <TextField
                  id="outlined-basic"
                  label="Expected THC"
                  variant="outlined"
                  fullWidth
                  color="success"
                  sx={{
                    color: "#17823B",
                    ".MuiOutlinedInput-notchedOutline": {
                      borderColor: "#17823B",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#17823B",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#17823B !important",
                    },
                  }}
                  InputProps={{
                    style: {
                      borderRadius: "10px",
                      height: 48,
                    },
                  }}
                />
              </div>

              <div className="row-sbc width100p gap20">
                <TextField
                  id="outlined-basic"
                  label="Expected CBD"
                  variant="outlined"
                  fullWidth
                  color="success"
                  sx={{
                    color: "#17823B",
                    ".MuiOutlinedInput-notchedOutline": {
                      borderColor: "#17823B",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#17823B",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#17823B !important",
                    },
                  }}
                  InputProps={{
                    style: {
                      borderRadius: "10px",
                      height: 48,
                    },
                  }}
                />

                <TextField
                  id="outlined-basic"
                  label="#Expected Grow Duration"
                  variant="outlined"
                  fullWidth
                  color="success"
                  sx={{
                    color: "#17823B",
                    ".MuiOutlinedInput-notchedOutline": {
                      borderColor: "#17823B",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#17823B",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#17823B !important",
                    },
                  }}
                  InputProps={{
                    style: {
                      borderRadius: "10px",
                      height: 48,
                    },
                  }}
                />
              </div>

              <div className="row-sbc width100p gap20">
                <TextField
                  id="outlined-basic"
                  label="Expected Plant Height"
                  variant="outlined"
                  fullWidth
                  color="success"
                  sx={{
                    color: "#17823B",
                    ".MuiOutlinedInput-notchedOutline": {
                      borderColor: "#17823B",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#17823B",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#17823B !important",
                    },
                  }}
                  InputProps={{
                    style: {
                      borderRadius: "10px",
                      height: 48,
                    },
                  }}
                />

                <TextField
                  id="outlined-basic"
                  label="Expected Turpines"
                  variant="outlined"
                  fullWidth
                  color="success"
                  sx={{
                    color: "#17823B",
                    ".MuiOutlinedInput-notchedOutline": {
                      borderColor: "#17823B",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#17823B",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#17823B !important",
                    },
                  }}
                  InputProps={{
                    style: {
                      borderRadius: "10px",
                      height: 48,
                    },
                  }}
                />
              </div>

              <TextField
                id="outlined-basic"
                label="Description"
                variant="outlined"
                fullWidth
                color="success"
                multiline
                rows={4}
                sx={{
                  color: "#17823B",
                  ".MuiOutlinedInput-notchedOutline": {
                    borderColor: "#17823B",
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#17823B",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#17823B !important",
                  },
                }}
                InputProps={{
                  style: {
                    borderRadius: "10px",
                  },
                }}
              />
            </div>
          </div>
        )}
      </Modal>

      {/* <Modal
                isOpen={editStrainModal}
                onRequestClose={() => setEditStrainModal(false)}
                className="strain-modal-base"
            >
                
            </Modal> */}

      <div className="row-sc gap5">
        <span className="green29">Dashboard</span>
        <span className="green85">/</span>
        <span className="green29">Inventory</span>
        <span className="green85">/</span>
        <span className="green29">Clones</span>
        <span className="green85">/</span>
        <span className="green85">001</span>
      </div>
      <div className="pd-base">
        <div className="row-sbc">
          <div className="row-sc gap10">
            <img src={ArrowLeftIcon} />
            <span className="green29">001</span>
          </div>

          <img src={ImageIcon} />
        </div>

        <div className="row-sbs width100p gap20">
          <div className="pd-yield-cards">
            <div className="grey-icon-back">
              <img src={ImageIcon} />
            </div>

            <div className="column-cs">
              <span className="green29">Numbers Planted</span>
              <span className="green29">137</span>
            </div>
          </div>

          <div className="pd-yield-cards">
            <div className="grey-icon-back">
              <img src={ImageIcon} />
            </div>

            <div className="column-cs">
              <span className="green29">total Yield Up To Date</span>
              <span className="green29">796g</span>
            </div>
          </div>
        </div>

        <div className="row-sbs width100p gap20">
          <div className="pd-detail-cards">
            <span className="green29">Details</span>
            <div className="row-sbc width100p">
              <span className="green29">ID:</span>
              <span className="green29">001</span>
            </div>

            <div className="row-sbc width100p">
              <span className="green29">Name:</span>
              <span className="green29">Sample Name</span>
            </div>

            <div className="row-sbc width100p">
              <span className="green29">Kind:</span>
              <span className="green29">Hybrid</span>
            </div>

            <div className="row-sbc width100p">
              <span className="green29">Strain:</span>
              <div
                className="row-sc gap10 pointer"
                onClick={() => setShowStrainModal(!showStrainModal)}
              >
                <img src={HandPointrightIcon} />
                <span className="green29">OG Kush</span>
              </div>
            </div>

            <div className="row-sbc width100p">
              <span className="green29">Origin:</span>
              <span className="green29">Own/Supplied</span>
            </div>

            <div className="row-sbc width100p">
              <span className="green29">Strain Type:</span>
              <span className="green29">Feminized/Auto</span>
            </div>

            <span className="green29">Long Description:</span>
            <span className="green29">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </span>

            <span className="green29">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </span>
          </div>

          <div className="pd-detail-cards">
            <span className="green29">Attributes</span>
            <div className="row-sbc width100p">
              <span className="green29">Harvesting Month:</span>
              <span className="green29">September</span>
            </div>

            <div className="row-sbc width100p">
              <span className="green29">Genetic Background:</span>
              <span className="green29">N/A</span>
            </div>

            <div className="row-sbc width100p">
              <span className="green29">Composition:</span>
              <span className="green29">N/A</span>
            </div>

            <div className="row-sbc width100p">
              <span className="green29">Effect:</span>
              <span className="green29">N/A</span>
            </div>

            <div className="row-sbc width100p">
              <span className="green29">Climate:</span>
              <span className="green29">N/A</span>
            </div>

            <div className="row-sbc width100p">
              <span className="green29">Flavor:</span>
              <span className="green29">N/A</span>
            </div>

            <span className="green29">Short Description:</span>
            <span className="green29">
              In Grow Lorem ipsum dolor sit amet, consectetur adipiscing elit,
              sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </span>
          </div>

          <div className="pd-detail-cards">
            <span className="green29">Sample Titel</span>

            <div className="row-sbs width100p">
              <div
                className="pd-estimate"
                onClick={() => {
                  setEstimatedClicked("estimated");
                }}
                style={{
                  borderBottom:
                    estimatedClicked === "estimated"
                      ? "2px solid #293D30"
                      : "2px solid #E6E6E6",
                }}
              >
                <span className="green29">Estimated</span>
                <div className="pd-number-back">
                  <span className="green29">21</span>
                </div>
              </div>

              <div
                className="pd-estimate"
                onClick={() => {
                  setEstimatedClicked("actual");
                }}
                style={{
                  borderBottom:
                    estimatedClicked === "actual"
                      ? "2px solid #293D30"
                      : "2px solid #E6E6E6",
                }}
              >
                <span className="green29">Actual</span>
                <div className="pd-number-back">
                  <span className="green29">21</span>
                </div>
              </div>
            </div>

            {estimatedClicked === "estimated" ? (
              <div className="pd-list">
                <div className="row-sbc width100p">
                  <span className="green29">THC:</span>
                  <span className="green29">32%</span>
                </div>

                <div className="row-sbc width100p">
                  <span className="green29">CBD:</span>
                  <span className="green29">20%</span>
                </div>

                <div className="row-sbc width100p">
                  <span className="green29">Yield:</span>
                  <span className="green29">50g</span>
                </div>

                <div className="row-sbc width100p">
                  <span className="green29">Grow Duration:</span>
                  <span className="green29">40 Days</span>
                </div>

                <div className="row-sbc width100p">
                  <span className="green29">Plant Height:</span>
                  <span className="green29">100cm</span>
                </div>

                <div className="row-sbc width100p">
                  <span className="green29">Turpines:</span>
                  <span className="green29">40g</span>
                </div>
              </div>
            ) : (
              <div className="pd-list">
                <div className="row-sbc width100p">
                  <span className="green29">THC:</span>
                  <span className="green29">100000%</span>
                </div>

                <div className="row-sbc width100p">
                  <span className="green29">CBD:</span>
                  <span className="green29">51215%</span>
                </div>

                <div className="row-sbc width100p">
                  <span className="green29">Yield:</span>
                  <span className="green29">00000.5g</span>
                </div>

                <div className="row-sbc width100p">
                  <span className="green29">Grow Duration:</span>
                  <span className="green29">40 Days</span>
                </div>

                <div className="row-sbc width100p">
                  <span className="green29">Plant Height:</span>
                  <span className="green29">100cm</span>
                </div>

                <div className="row-sbc width100p">
                  <span className="green29">Turpines:</span>
                  <span className="green29">40g</span>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="pd-notes">
          <span className="green29">Notes</span>
          <Textarea
            minRows={4}
            className="pd-notes-input"
            // color="#669977"
            sx={{
              "--Textarea-focusedThickness": "0.01rem",
              "--Textarea-focusedHighlight": "#669977",

              "&:focus-within": {
                borderColor: "#669977",
              },
            }}
          />

          <div className="row-ss gap20">
            <span className="green29">18 Dec 2023</span>
            <div className="column-ss gap20">
              <span className="green29">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </span>
              <span className="green29">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CloneDetails;
