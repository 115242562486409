import "./Seeds.css";
import React, { useState, useEffect } from "react";
import PlusIcon from "../../assets/images/plus.svg";
import CheckSquareIcon from "../../assets/images/check-square.svg";
import UpDowneIcon from "../../assets/images/up-down.svg";
import EmptySquareIcon from "../../assets/images/empty-square.svg";
import RightIcon from "../../assets/images/right.svg";
import LeftGoIcon from "../../assets/images/left-go.svg";
import LeftEndIcon from "../../assets/images/left-end.svg";
import RightGoIcon from "../../assets/images/right-go.svg";
import RightEndIcon from "../../assets/images/right-end.svg";
import DownFilledIcon from "../../assets/images/down-filled.svg";
import SaveBrightIcon from "../../assets/images/save-bright.svg";
import Autocomplete from "@mui/material/Autocomplete";
import Modal from "react-modal";
import CloseIcon from "../../assets/images/close.svg";
import TextField from "@mui/material/TextField";
import authSlice from "../../store/slices/auth";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import axiosService from "../../utils/AxiosService";
import { useSelector } from "react-redux";

function Seeds() {
  const [newSeedModal, setNewSeedModal] = useState(false);
  const [strain, setStrain] = React.useState("");
  const [seeds, setSeeds] = React.useState([]);
  const [numSelected, setNumSelected] = React.useState(0);
  const [seedCatalogue, setSeedCatalogue] = React.useState([]);
  const [seedCatalogueNames, setSeedCatalogueNames] = React.useState([]);
  const [name, setName] = React.useState("");
  const [quantity, setQuantity] = React.useState(null);
  const [origin, setOrigin] = React.useState("");
  const [status, setStatus] = React.useState("");
  const [supplier, setSupplier] = React.useState("");
  const [manufacturer, setManufacturer] = React.useState("");
  const [shortDescription, setShortDescription] = React.useState("");
  const [longDescription, setLongDescription] = React.useState("");
  const user = useSelector((state) => state.auth.account);

  useEffect(() => {
    getSeeds();
    getSeedCatalogue();
  }, []);

  const getSeeds = () => {
    axiosService
      .get("/inventory/seedAndClone/")
      .then((response) => {
        const tmp = response.data;
        tmp.forEach((element) => {
          element.selected = false;
        });

        setSeeds(tmp);
      })
      .catch((error) => console.log("Error fetching content URL:", error));
  };
  const addSeeds = () => {
    axiosService
      .post("/inventory/seedAndClone/", {
        name: name,
        user: user.account,
        strain: strain,
        origin: origin,
        status: status,
        supplier: supplier,
        manufacturer: manufacturer,
        shortDescription: shortDescription,
        longDescription: longDescription,
      })
      .then((response) => {
        getSeeds();
        setNewSeedModal(!newSeedModal);
      })
      .catch((error) => console.log("Error fetching content URL:", error));
  };

  const getSeedCatalogue = () => {
    axiosService
      .get("/inventory/seedCatalogue/")
      .then((response) => {
        const names = [];
        const tmp = response.data;
        tmp.forEach((element) => {
          names.push(element.strain);
        });

        setSeedCatalogue(tmp);
        setSeedCatalogueNames(names);
      })
      .catch((error) => console.log("Error fetching content URL:", error));
  };

  const handleSelectItem = (item) => {
    const tmp = seeds.map((seed) => {
      if (seed.id === item.id) {
        if (seed.selected) {
          setNumSelected(numSelected - 1);
        }
        if (!seed.selected) {
          setNumSelected(numSelected + 1);
        }
        return { ...seed, selected: !seed.selected };
      }
      return seed;
    });
    setSeeds(tmp);
  };

  const selectAll = () => {
    const tmp = seeds.map((seed) => {
      if (seed.selected) {
        setNumSelected(numSelected - 1);
      }
      if (!seed.selected) {
        setNumSelected(numSelected + 1);
      }
      return { ...seed, selected: !seed.selected };
    });
    setSeeds(tmp);
  };

  return (
    <div className="container-padding">
      <Modal
        isOpen={newSeedModal}
        onRequestClose={() => setNewSeedModal(false)}
        className="strain-modal-base"
      >
        <div className="edit-strain-modal">
          <div className="row-sbc width100p">
            <span className="green29">Add New Seed</span>

            <div className="row-sc gap20">
              <div className="row-sc gap10 pointer">
                <img src={SaveBrightIcon} onClick={addSeeds} />
                <span className="green29">Save</span>
              </div>
              <img
                src={CloseIcon}
                className="pointer"
                onClick={() => setNewSeedModal(!newSeedModal)}
              />
            </div>
          </div>

          <div className="row-sbc width100p gap20">
            <FormControl fullWidth>
              {/* <InputLabel
                id="demo-simple-select-label"
                sx={{ color: "#17823B !important" }}
              >
                Strain
              </InputLabel> */}
              <Autocomplete
                disablePortal
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={strain}
                onChange={(event, newValue) => {
                  setStrain(newValue);
                }}
                options={seedCatalogueNames}
                sx={{
                  color: "#17823B",
                  ".MuiOutlinedInput-notchedOutline": {
                    borderColor: "#17823B",
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#17823B",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#17823B",
                  },
                  ".MuiSvgIcon-root ": {
                    fill: "#17823B !important",
                  },
                  borderRadius: 3,
                }}
                renderInput={(params) => (
                  <TextField {...params} label="strain" />
                )}
              />
              {/* <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={strain}
                label="Strain"
                color="success"
                

                // onChange={handleChange}
              >
                <MenuItem value={10}>OG Kush</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem>
              </Select> */}
            </FormControl>

            {/* <div className="add-seed-button ">
              <img src={PlusIcon} />
              <span className="green29">Add New Seed</span>
            </div> */}
          </div>

          <div className="row-sbc width100p gap20">
            <TextField
              id="outlined-basic"
              label="Name"
              variant="outlined"
              value={name}
              onChange={(e) => setName(e.target.value)}
              fullWidth
              color="success"
              sx={{
                color: "#17823B",
                ".MuiOutlinedInput-notchedOutline": {
                  borderColor: "#17823B",
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#17823B",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#17823B !important",
                },
              }}
              InputProps={{
                style: {
                  borderRadius: "10px",
                  height: 56,
                },
              }}
            />
            <TextField
              id="outlined-basic"
              label="Quantity"
              value={quantity}
              type="number"
              onChange={(e) => setQuantity(e.target.value)}
              variant="outlined"
              fullWidth
              color="success"
              style={{ width: 120 }}
              sx={{
                color: "#17823B",
                ".MuiOutlinedInput-notchedOutline": {
                  borderColor: "#17823B",
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#17823B",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#17823B !important",
                },
              }}
              InputProps={{
                style: {
                  borderRadius: "10px",
                  height: 56,
                },
              }}
            />
          </div>

          <div className="row-sbc width100p gap20">
            <TextField
              id="outlined-basic"
              label="Origin"
              value={origin}
              onChange={(e) => setOrigin(e.target.value)}
              variant="outlined"
              fullWidth
              color="success"
              sx={{
                color: "#17823B",
                ".MuiOutlinedInput-notchedOutline": {
                  borderColor: "#17823B",
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#17823B",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#17823B !important",
                },
              }}
              InputProps={{
                style: {
                  borderRadius: "10px",
                  height: 56,
                },
              }}
            />
            <TextField
              id="outlined-basic"
              label="Status"
              value={status}
              onChange={(e) => setStatus(e.target.value)}
              variant="outlined"
              fullWidth
              color="success"
              sx={{
                color: "#17823B",
                ".MuiOutlinedInput-notchedOutline": {
                  borderColor: "#17823B",
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#17823B",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#17823B !important",
                },
              }}
              InputProps={{
                style: {
                  borderRadius: "10px",
                  height: 56,
                },
              }}
            />
          </div>

          <div className="row-sbc width100p gap20">
            <TextField
              id="outlined-basic"
              label="Supplier"
              value={supplier}
              onChange={(e) => setSupplier(e.target.value)}
              variant="outlined"
              fullWidth
              color="success"
              sx={{
                color: "#17823B",
                ".MuiOutlinedInput-notchedOutline": {
                  borderColor: "#17823B",
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#17823B",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#17823B !important",
                },
              }}
              InputProps={{
                style: {
                  borderRadius: "10px",
                  height: 56,
                },
              }}
            />
            <TextField
              id="outlined-basic"
              label="Manufacturer"
              value={manufacturer}
              onChange={(e) => setManufacturer(e.target.value)}
              variant="outlined"
              fullWidth
              color="success"
              sx={{
                color: "#17823B",
                ".MuiOutlinedInput-notchedOutline": {
                  borderColor: "#17823B",
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#17823B",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#17823B !important",
                },
              }}
              InputProps={{
                style: {
                  borderRadius: "10px",
                  height: 56,
                },
              }}
            />
          </div>

          <TextField
            id="outlined-basic"
            label="Short Description"
            value={shortDescription}
            onChange={(e) => setShortDescription(e.target.value)}
            variant="outlined"
            fullWidth
            color="success"
            multiline
            rows={2}
            sx={{
              color: "#17823B",
              ".MuiOutlinedInput-notchedOutline": {
                borderColor: "#17823B",
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "#17823B",
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "#17823B !important",
              },
            }}
            InputProps={{
              style: {
                borderRadius: "10px",
              },
            }}
          />

          <TextField
            id="outlined-basic"
            label="Long Description"
            value={longDescription}
            onChange={(e) => setLongDescription(e.target.value)}
            variant="outlined"
            fullWidth
            color="success"
            multiline
            rows={4}
            sx={{
              color: "#17823B",
              ".MuiOutlinedInput-notchedOutline": {
                borderColor: "#17823B",
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "#17823B",
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "#17823B !important",
              },
            }}
            InputProps={{
              style: {
                borderRadius: "10px",
              },
            }}
          />
        </div>
      </Modal>

      <div className="row-sc gap5 pad-b20">
        <span className="green29">Dashboard</span>
        <span className="green85">/</span>
        <span className="green29">Inventory</span>
        <span className="green85">/</span>
        <span className="green85">Seeds</span>
      </div>
      <div className="pd-base">
        <span className="green29">Seeds</span>

        <button
          className="new-seed-button"
          onClick={() => setNewSeedModal(!newSeedModal)}
        >
          <img src={PlusIcon} />
          <span className="green29">Add New Seed</span>
        </button>

        <div className="plants-card">
          <div className="plant-card-row">
            <div className="row-sc width-check">
              <img
                onClick={selectAll}
                src={numSelected === 0 ? EmptySquareIcon : CheckSquareIcon}
              />
            </div>

            <div className="row-sc width-id gap5">
              <span className="green29">ID</span>
              <img src={UpDowneIcon} />
            </div>

            <div className="row-sc width-type gap5">
              <span className="green29">Name</span>
              <img src={UpDowneIcon} />
            </div>

            <div className="row-sc width-type gap5">
              <span className="green29">Kind</span>
              <img src={UpDowneIcon} />
            </div>

            <div className="row-sc width-type gap5">
              <span className="green29">Strain</span>
              <img src={UpDowneIcon} />
            </div>

            <div className="row-sc width-type gap5">
              <span className="green29">THC</span>
              <img src={UpDowneIcon} />
            </div>

            <div className="row-sc width-type gap5">
              <span className="green29">CBD</span>
              <img src={UpDowneIcon} />
            </div>

            <div className="row-sc width-type gap5">
              <span className="green29">Status</span>
              <img src={UpDowneIcon} />
            </div>
          </div>
          {seeds.map((seed) => {
            return (
              <div key={seed.id} className="plant-card-row">
                <div className="row-sc width-check">
                  <div onClick={() => handleSelectItem(seed)}>
                    {!seed.selected ? (
                      <img alt="check-square" src={EmptySquareIcon} />
                    ) : (
                      <img alt="check-square-checked" src={CheckSquareIcon} />
                    )}
                  </div>
                </div>

                <div className="row-sc width-id gap5">
                  <a href="/seed_details">
                    <img src={RightIcon} />
                    <span className="green29">{seed.id}</span>
                  </a>
                </div>

                <div className="row-sc width-type gap5">
                  <img src={RightIcon} />
                  <span className="green29">{seed.name}</span>
                </div>

                <div className="row-sc width-type gap5">
                  <img src={RightIcon} />
                  {seed.composition ? (
                    <span className="green29">
                      {seed.composition.length === 0
                        ? "-"
                        : seed.composition.length === 1
                        ? seed.composition[0].name
                        : "Hybrid"}
                    </span>
                  ) : (
                    <span className="green29">-</span>
                  )}
                </div>

                <div className="row-sc width-type gap5">
                  <img src={RightIcon} />
                  <span className="green29">{seed.strain}</span>
                </div>

                <div className="row-sc width-type gap5">
                  <img src={RightIcon} />
                  <span className="green29">{seed.expectedTHClevel}</span>
                </div>

                <div className="row-sc width-type gap5">
                  <img src={RightIcon} />
                  <span className="green29">{seed.expectedCBDLevel}</span>
                </div>

                <div className="row-sc width-type gap5">
                  <img src={RightIcon} />
                  <span className="green29">{seed.status}</span>
                </div>
              </div>
            );
          })}

          <div className="pages-end-row">
            <span className="green29">{numSelected} Selected</span>

            <div className="plants-pages">
              <span className="green29">Rows/page</span>
              <div className="rows-outline">
                <span className="green29">10</span>
                <img src={DownFilledIcon} />
              </div>

              <span className="green29">1-10 of 75</span>

              <img src={LeftEndIcon} />
              <img src={LeftGoIcon} />
              <img src={RightGoIcon} />
              <img src={RightEndIcon} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Seeds;
