import './ToDoList.css';
import React, { useState, useEffect } from "react";
import PlusWhiteIcon from "../../assets/images/plus-white.svg"

import CheckSquareIcon from "../../assets/images/check-square.svg";
import MinusSquareIcon from "../../assets/images/minus-square.svg"
import UpDowneIcon from "../../assets/images/up-down.svg";
import EmptySquareIcon from "../../assets/images/empty-square.svg";
import RightIcon from "../../assets/images/right.svg";
import LeftGoIcon from "../../assets/images/left-go.svg";
import LeftEndIcon from "../../assets/images/left-end.svg";
import RightGoIcon from "../../assets/images/right-go.svg";
import RightEndIcon from "../../assets/images/right-end.svg";
import DownFilledIcon from "../../assets/images/down-filled.svg";


function ToDoList() {

    const [isClicked, setIsClicked] = useState("all");

    return (
        <div className='container-padding'>

            <div className='todo-base'>
                <span className='green29'>To-Do List</span>
                <div className='row-sbe width100p'>
                    <div className='tasks'
                        style={{ borderBottom: isClicked === "all" ? "2px solid #293D30" : " 2px solid #C2D6C9" }}
                        onClick={() => setIsClicked("all")}>
                        <span className='green29'>All Tasks</span>
                    </div>

                    <div className='tasks'
                        style={{ borderBottom: isClicked === "todays_tasks" ? "2px solid #293D30" : " 2px solid #C2D6C9" }}
                        onClick={() => setIsClicked("todays_tasks")}>
                        <span className='green29'>Today's Tasks</span>
                    </div>
                    <div className='tasks'
                        style={{ borderBottom: isClicked === "completed_tasks" ? "2px solid #293D30" : " 2px solid #C2D6C9" }}
                        onClick={() => setIsClicked("completed_tasks")}>
                        <span className='green29'>Completed Tasks</span>
                    </div>
                    <div className='tasks'
                        style={{ borderBottom: isClicked === "deleted_tasks" ? "2px solid #293D30" : " 2px solid #C2D6C9" }}
                        onClick={() => setIsClicked("deleted_tasks")}>
                        <span className='green29'>Deleted Tasks</span>
                    </div>
                </div>


                <button className='new-plant-button'>
                    <img src={PlusWhiteIcon} />
                    <span className='white'>New Task</span>
                </button>

                <div className='todo-card'>
                    <div className='todo-card-row-top'>
                        <div className='row-sc width-check' >
                            <img src={MinusSquareIcon} />
                        </div>

                        <div className='row-sc width-id gap5'>
                            <span className='green29'>Name</span>
                           
                        </div>

                        <div className='row-sc width-type gap5'>
                            <span className='green29'>Status</span>
                           
                        </div>

                        <div className='row-sc width-type gap5'>
                            <span className='green29'>Grow Space</span>
                          
                        </div>

                        <div className='row-sc width-type gap5'>
                            <span className='green29'>Batch</span>
                           
                        </div>

                        <div className='row-sc width-type gap5'>
                            <span className='green29'>Start Date</span>
                           
                        </div>

                        <div className='row-sc width-type gap5'>
                            <span className='green29'>Start Time</span>
                           
                        </div>

                        <div className='row-sc width-type gap5'>
                            <span className='green29'>Due Date</span>
                           
                        </div>
                    </div>

                    <div className='todo-card-row'>
                        <div className='row-sc width-check' >
                            <img src={EmptySquareIcon} />
                        </div>

                        <div className='row-sc width-id gap5'>
                           
                            <span className='green29'>001</span>

                        </div>

                        <div className='row-sc width-type gap5'>
                         
                            <span className='green29'>OG Kush</span>
                        </div>

                        <div className='row-sc width-type gap5'>
                          
                            <span className='green29'>Sup_A</span>
                        </div>

                        <div className='row-sc width-type gap5'>
                           
                            <span className='green29'>Hybrid</span>
                        </div>

                        <div className='row-sc width-summary gap5'>
                         
                            <span className='green29'>Buruburuburu</span>
                        </div>
                    </div>


                    
                </div>

                <div className='row-sbc width100p pad-t20'>
                        <span className='green29'>2 Selected</span>

                        <div className='plants-pages'>
                            <span className='green29'>Rows/page</span>
                            <div className='rows-outline'>

                                <span className='green29'>10</span>
                                <img src={DownFilledIcon} />
                            </div>

                            <span className='green29'>1-10 of 75</span>

                            <img src={LeftEndIcon} />
                            <img src={LeftGoIcon} />
                            <img src={RightGoIcon} />
                            <img src={RightEndIcon} />
                        </div>
                    </div>

            </div>
        </div>
    );
}

export default ToDoList;