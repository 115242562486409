import "./Batches.css";
import React, { useState } from "react";
import ArrowLeftIcon from "../../assets/images/arrow-left.svg";
import ImageIcon from "../../assets/images/image.svg";
import ScullIcon from "../../assets/images/scull.svg";
import SwapIcon from "../../assets/images/swap.svg";
import RightFromIcon from "../../assets/images/right-from.svg";
import SackIcon from "../../assets/images/sack.svg";
import ScissorsIcon from "../../assets/images/scissors.svg";
import TrashIcon from "../../assets/images/trash.svg";
import MinusSquareIcon from "../../assets/images/minus-square.svg";
import EmptySquareIcon from "../../assets/images/empty-square.svg";
import CheckSquareIcon from "../../assets/images/check-square.svg";
import DownFilledIcon from "../../assets/images/down-filled.svg";
import LeftGoIcon from "../../assets/images/left-go.svg";
import LeftEndIcon from "../../assets/images/left-end.svg";
import RightGoIcon from "../../assets/images/right-go.svg";
import RightEndIcon from "../../assets/images/right-end.svg";
import CheckDoubleIcon from "../../assets/images/check-double.svg";
import PlusWhiteIcon from "../../assets/images/plus-white.svg";
import axiosService from "../../utils/AxiosService";

function Batches() {
  const [batch, setBatch] = useState({});
  return (
    <div className="container-padding gap20">
      <div className="first-batch">
        <div className="row-sc gap10">
          <img src={ArrowLeftIcon} />
          <span className="green29">My First Batch</span>
        </div>

        <div className="row-sc gap5">
          <span className="green29">Dashboard</span>
          <span className="green85">/</span>
          <span className="green29">Batches</span>
          <span className="green85">/</span>
          <span className="green85">My First Batch</span>
        </div>
      </div>

      <div className="batches-overview">
        <div className="batches-overview-card">
          <div className="grey-icon-back">
            <img src={ImageIcon} />
          </div>

          <div className="column-ss gap5">
            <span className="grey99">Total Plants</span>
            <span className="green29">75</span>
          </div>
        </div>

        <div className="batches-overview-card">
          <div className="grey-icon-back">
            <img src={ImageIcon} />
          </div>

          <div className="column-ss gap5">
            <span className="grey99">Grow Space</span>
            <span className="green29">Tent #3</span>
          </div>
        </div>

        <div className="batches-overview-card">
          <div className="grey-icon-back">
            <img src={ImageIcon} />
          </div>

          <div className="column-ss gap5">
            <span className="grey99">Current Phase</span>
            <span className="green29">Vegetative</span>
          </div>
        </div>

        <div className="batches-overview-card">
          <div className="grey-icon-back">
            <img src={ImageIcon} />
          </div>

          <div className="column-ss gap5">
            <span className="grey99">Plant Survival Rate</span>
            <span className="green29">90%</span>
          </div>
        </div>
      </div>

      <div className="row-sbs width100p">
        <div className="column-ss gap20" style={{ width: "30%" }}>
          <div className="batch-details-card">
            <span className="green29">Batch Details</span>
            <div className="row-sbc width100p">
              <span className="green29">Batch ID:</span>
              <span className="green29">456048462</span>
            </div>

            <div className="row-sbc width100p">
              <span className="green29">Date Planted:</span>
              <span className="green29">7th Nov, 2023</span>
            </div>

            <div className="row-sbc width100p">
              <span className="green29">Estimated Harvest Date:</span>
              <span className="green29">21st Feb, 2024</span>
            </div>

            <div className="row-sbc width100p">
              <span className="green29">Origin</span>
              <span className="green29">Seed</span>
            </div>

            <div className="row-sbc width100p">
              <span className="green29">Status:</span>
              <span className="green29">Active</span>
            </div>
          </div>

          <div className="batch-summary-card">
            <span className="green29">Batch Summary</span>

            <div className="summary-head" style={{ justifySelf: "flex-end" }}>
              <div className="no-border">
                <span className="grey4D">Total</span>
              </div>
              <div className="no-border">
                <span className="grey4D">Avg./Plant</span>
              </div>
            </div>

            <div className="summary-row">
              <span className="grey4D">Estimated Wet Weight</span>

              <div className="row-ec height100p">
                <div className="border-lr">
                  <span className="grey4D">1080g</span>
                </div>
                <div className="no-border">
                  <span className="grey4D">14.4g</span>
                </div>
              </div>
            </div>

            <div className="sep-lightgreen" />

            <div className="summary-row">
              <span className="grey4D">Estimated Dry Weight</span>

              <div className="row-ec height100p">
                <div className="border-lr">
                  <span className="grey4D">1080g</span>
                </div>
                <div className="no-border">
                  <span className="grey4D">14.4g</span>
                </div>
              </div>
            </div>

            <div className="sep-lightgreen" />

            <div className="summary-row">
              <span className="grey4D">Estimated Waste Weight</span>

              <div className="row-ec height100p">
                <div className="border-lr">
                  <span className="grey4D">1080g</span>
                </div>
                <div className="no-border">
                  <span className="grey4D">14.4g</span>
                </div>
              </div>
            </div>

            <div className="sep-lightgreen" />

            <div className="summary-row">
              <span className="grey4D">Estimated A Bud Weight</span>

              <div className="row-ec height100p">
                <div className="border-lr">
                  <span className="grey4D">1080g</span>
                </div>
                <div className="no-border">
                  <span className="grey4D">14.4g</span>
                </div>
              </div>
            </div>

            <div className="sep-lightgreen" />

            <div className="summary-row">
              <span className="grey4D">Estimated B Bud Weight</span>

              <div className="row-ec height100p">
                <div className="border-lr">
                  <span className="grey4D">1080g</span>
                </div>
                <div className="no-border">
                  <span className="grey4D">14.4g</span>
                </div>
              </div>
            </div>

            <div className="sep-lightgreen" />

            <div className="summary-row">
              <span className="grey4D">Estimated Trim Weight</span>

              <div className="row-ec height100p">
                <div className="border-lr">
                  <span className="grey4D">50g</span>
                </div>
                <div className="no-border">
                  <span className="grey4D">0.6g</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="plant-card">
          <span className="green29">Plants</span>

          <div className="row-sbc width100p">
            <button className="new-plant-button">
              <img src={PlusWhiteIcon} />
              <span className="white">New Plant</span>
            </button>

            <div className="row-sc gap15">
              <img src={ScullIcon} />
              <img src={SwapIcon} />
              <img src={RightFromIcon} />
              <img src={SackIcon} />
              <img src={ScissorsIcon} />
              <img src={TrashIcon} />
            </div>
          </div>

          <div className="plant-card-inner">
            <div
              className="row-sbc width100p"
              style={{
                backgroundColor: "#F5F5F5",
                borderTopRightRadius: 10,
                borderTopLeftRadius: 10,
              }}
            >
              <div className="plant-card-half gap20">
                <img src={MinusSquareIcon} />
                <span className="grey4D">Plant ID</span>
              </div>
              <div className="plant-card-half">
                <span className="grey4D">Strain</span>
              </div>
            </div>

            <div className="sep-lightgreen" />

            <div className="row-sbc width100p">
              <div className="plant-card-half gap20">
                <img src={EmptySquareIcon} />
                <span className="grey4D">000000001</span>
              </div>
              <div className="plant-card-half">
                <span className="grey4D">OG Kush</span>
              </div>
            </div>

            <div className="sep-lightgreen" />

            <div
              className="row-sbc width100p"
              style={{
                backgroundColor: "#E0E0E0",
                borderBottomRightRadius: 10,
                borderBottomLeftRadius: 10,
              }}
            >
              <div className="plant-card-half gap20">
                <img src={CheckSquareIcon} />
                <span className="grey4D">000000001</span>
              </div>
              <div className="plant-card-half">
                <span className="grey4D">OG Kush</span>
              </div>
            </div>
          </div>

          <div className="plant-card-end">
            <span className="green29">Rows/page</span>
            <div className="rows-outline">
              <span className="green29">10</span>
              <img src={DownFilledIcon} />
            </div>

            <span className="green29">1-10 of 75</span>

            <img src={LeftEndIcon} />
            <img src={LeftGoIcon} />
            <img src={RightGoIcon} />
            <img src={RightEndIcon} />
          </div>
        </div>

        <div className="phase-progress">
          <span className="green29">Phase Progress</span>
          <div className="row-ss">
            <div className="progress-line-column">
              <div className="progress-icon-done">
                <img src={CheckDoubleIcon} />
              </div>
              <div className="progress-line" />
              <div className="progress-icon-done">
                <img src={CheckDoubleIcon} />
              </div>
              <div className="progress-line" />

              <div className="progress-icon-done">
                <img src={CheckDoubleIcon} />
              </div>
              <div className="progress-line" />

              <div className="progress-icon-undone">
                <span className="green29">70%</span>
              </div>

              <div className="progress-line-undone" />

              <div className="progress-icon-undone">
                <span className="green29">0%</span>
              </div>
            </div>
            <div className="progress-column">
              <span className="green29">Planted</span>
              <span className="green29">Seedling</span>
              <span className="green29">Germination</span>
              <span className="green29">Vegetative</span>
              <span className="green29">Flowering</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Batches;
