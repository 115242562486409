import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useLocation,
} from "react-router-dom";
import Home from "../src/screens/home/Home";
import Navigation from "./components/navigation/Navigation";
import Header from "../src/components/header/Header";
import Batches from "./screens/batches/Batches";
import ToDoList from "./screens/to-do-list/ToDoList";
import GrowSpaces from "./screens/grow-spaces/GrowSpaces";
import Plants from "./screens/plants/Plants";
import PlantDetails from "./screens/plant-details/PlantDetails";
import Seeds from "./screens/seeds/Seeds";
import SeedDetails from "./screens/seed-details/SeedDetails";
import Devices from "./screens/devices/Devices";
import { Provider } from "react-redux";
import { store, persistor } from "./store";
import Signup from "./screens/signup/Signup";
import Login from "./screens/login/Login";
import Clones from "./screens/clones/Clones";
import CloneDetails from "./screens/clone-details/CloneDetails";

export default function AppWrapper() {
  return (
    <Provider store={store}>
      <Router>
        <App />
      </Router>
    </Provider>
  );
}

function App() {
  const location = useLocation();
  const hideNav = ["/login", "/signup"].includes(location.pathname); // Determine when to hide navigation

  return (
    <div style={{ backgroundColor: "#FAFAFA" }}>
      <Header />
      <div style={{ display: "flex", flexDirection: "row" }}>
        {!hideNav && <Navigation />}

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/batches" element={<Batches />} />
          <Route path="/to_do_list" element={<ToDoList />} />
          <Route path="/grow_spaces" element={<GrowSpaces />} />
          <Route path="/plants" element={<Plants />} />
          <Route path="/plant_details" element={<PlantDetails />} />
          <Route path="/seeds" element={<Seeds />} />
          <Route path="/clones" element={<Clones />} />
          <Route path="/clone_details" element={<CloneDetails />} />
          <Route path="/seed_details" element={<SeedDetails />} />
          <Route path="/devices" element={<Devices />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/login" element={<Login />} />
        </Routes>
      </div>
    </div>
  );
}
