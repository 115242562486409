import "./Home.css";
import React from "react";
// import "../../components/navigation/Navigation"

function Home() {
  return (
    <div className="container-padding">
      {/* <Navigation /> */}
      <span style={{ fontSize: 20, color: "green" }}>Dashboard </span>
    </div>
  );
}

export default Home;
