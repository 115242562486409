import "./GrowSpaces.css";
import React, { useState, useEffect } from "react";
import axiosService from "../../utils/AxiosService";
import PlusWhiteIcon from "../../assets/images/plus-white.svg";
import CheckSquareIcon from "../../assets/images/check-square.svg";
import UpDowneIcon from "../../assets/images/up-down.svg";
import EmptySquareIcon from "../../assets/images/empty-square.svg";
import RightIcon from "../../assets/images/right.svg";
import LeftGoIcon from "../../assets/images/left-go.svg";
import LeftEndIcon from "../../assets/images/left-end.svg";
import RightGoIcon from "../../assets/images/right-go.svg";
import RightEndIcon from "../../assets/images/right-end.svg";
import DownFilledIcon from "../../assets/images/down-filled.svg";

function GrowSpaces() {
  const [growSpaces, setGrowSpaces] = useState([]);
  const [numSelected, setNumSelected] = React.useState(0);

  useEffect(() => {
    getGrowSpaces();
  }, []);

  const selectAll = () => {
    const tmp = growSpaces.map((plant) => {
      if (plant.selected) {
        setNumSelected(numSelected - 1);
      }
      if (!plant.selected) {
        setNumSelected(numSelected + 1);
      }
      return { ...plant, selected: !plant.selected };
    });
    setGrowSpaces(tmp);
  };

  const handleSelectItem = (item) => {
    const tmp = growSpaces.map((plant) => {
      if (plant.id === item.id) {
        if (plant.selected) {
          setNumSelected(numSelected - 1);
        }
        if (!plant.selected) {
          setNumSelected(numSelected + 1);
        }
        return { ...plant, selected: !plant.selected };
      }
      return plant;
    });
    setGrowSpaces(tmp);
  };

  const getGrowSpaces = () => {
    axiosService
      .get("/inventory/space/")
      .then((response) => {
        const tmp = response.data;
        tmp.forEach((element) => {
          element.selected = false;
        });

        setGrowSpaces(tmp);
      })
      .catch((error) => console.log("Error fetching content URL:", error));
  };
  return (
    <div className="container-padding">
      <div className="row-sc gap5">
        <span className="green29">Dashboard</span>
        <span className="green85">/</span>
        <span className="green29">Inventory</span>
        <span className="green85">/</span>
        <span className="green85">Grow Spaces</span>
      </div>
      <div className="todo-base">
        <span className="green29">Grow Spaces</span>

        {/* <button className="new-plant-button">
        <img src={PlusWhiteIcon} />
        <span className="white">Add New Plant</span>
      </button> */}

        <div className="plants-card">
          <div className="plant-card-row">
            <div className="row-sc width-check">
              <img
                onClick={selectAll}
                src={numSelected === 0 ? EmptySquareIcon : CheckSquareIcon}
              />
            </div>

            <div className="row-sc width-id gap5">
              <span className="green29">ID</span>
              <img src={UpDowneIcon} />
            </div>

            <div className="row-sc width-type gap5">
              <span className="green29">Type</span>
              <img src={UpDowneIcon} />
            </div>

            <div className="row-sc width-type gap5">
              <span className="green29">Supplier</span>
              <img src={UpDowneIcon} />
            </div>

            <div className="row-sc width-type gap5">
              <span className="green29">Kind</span>
              <img src={UpDowneIcon} />
            </div>

            <div className="row-sc width-summary gap5">
              <span className="green29">Summary</span>
              <img src={UpDowneIcon} />
            </div>
          </div>
          {growSpaces.map((plant) => {
            return (
              <div className="plant-card-row">
                <div className="row-sc width-check">
                  <div onClick={() => handleSelectItem(plant)}>
                    {!plant.selected ? (
                      <img alt="check-square" src={EmptySquareIcon} />
                    ) : (
                      <img alt="check-square-checked" src={CheckSquareIcon} />
                    )}
                  </div>
                </div>

                <div className="row-sc width-id gap5">
                  <img src={RightIcon} />
                  <span className="green29">{plant.id}</span>
                </div>

                <div className="row-sc width-type gap5">
                  <img src={RightIcon} />
                  <span className="green29">{plant.strain}</span>
                </div>

                <div className="row-sc width-type gap5">
                  <img src={RightIcon} />
                  <span className="green29">{plant.supplier}</span>
                </div>

                <div className="row-sc width-type gap5">
                  <img src={RightIcon} />
                  {plant.composition ? (
                    <span className="green29">
                      {plant.composition.length === 0
                        ? "-"
                        : plant.composition.length === 1
                        ? plant.composition[0].name
                        : "Hybrid"}
                    </span>
                  ) : (
                    <span className="green29">-</span>
                  )}
                </div>

                <div className="row-sc width-summary gap5">
                  <img src={RightIcon} />
                  <span className="green29">Buruburuburu</span>
                </div>
              </div>
            );
          })}

          <div className="row-sbc width100p pad-t20">
            <span className="green29">{numSelected} Selected</span>

            <div className="plants-pages">
              <span className="green29">Rows/page</span>
              <div className="rows-outline">
                <span className="green29">10</span>
                <img src={DownFilledIcon} />
              </div>

              <span className="green29">1-10 of 75</span>

              <img src={LeftEndIcon} />
              <img src={LeftGoIcon} />
              <img src={RightGoIcon} />
              <img src={RightEndIcon} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GrowSpaces;
