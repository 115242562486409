import axios from "axios";
import { store } from "../store";
import authSlice from "../store/slices/auth";

const axiosService = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

axiosService.interceptors.request.use(async (config) => {
  const { token } = store.getState().auth;

  if (token && config.headers) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

axiosService.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;

    // Check if the request was not already a token refresh attempt
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true; // Marking request as retried
      const refresh = store.getState().auth.refresh;
      console.log("REFRESH", refresh);

      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/accounts/auth/token/refresh/`,
          {
            refresh: refresh,
          }
        );

        if (response.status === 200) {
          store.dispatch(
            authSlice.actions.setAuthToken({ access: response.data.access })
          ); // Correctly update the refresh token

          // Update the original request's Authorization header with the new token
          axiosService.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${refresh}`;
          originalRequest.headers["Authorization"] = `Bearer ${refresh}`;

          // Return the originalRequest to retry it with the new token
          return axiosService(originalRequest);
        }
      } catch (refreshError) {
        console.error("Unable to refresh token:", refreshError);
        // store.dispatch(authSlice.actions.setLogout()); // Ensure logout on failure
        return Promise.reject(refreshError); // Reject with the new error
      }
    }

    // For errors other than 401 or if the refresh token process fails
    return Promise.reject(error);
  }
);

export default axiosService;

// axiosService.interceptors.request.use(async (config) => {
//   const { token } = store.getState().auth;

//   if (token && config.headers) {
//     config.headers.Authorization = `Bearer ${token}`;
//   }
//   return config;
// });

// axiosService.interceptors.response.use(
//   (response) => {
//     return response;
//   },
//   async (error) => {
//     const originalRequest = error.config;

//     // Check if the request was not already a token refresh attempt
//     if (error.response.status === 401 && !originalRequest._retry) {
//       originalRequest._retry = true; // Marking request as retried
//       const refresh = store.getState().auth.refresh;
//       console.log("REFRESH",refresh)

//       try {
//         const response = await axios.post(`${process.env.REACT_APP_API_URL}/auth/token/refresh/`, {
//           refresh: refresh
//         });

//         if (response.status === 200) {
//           const { refresh } = response.data;
//           store.dispatch(authSlice.actions.setRefreshToken({ refresh })); // Correctly update the refresh token

//           // Update the original request's Authorization header with the new token
//           axiosService.defaults.headers.common['Authorization'] = `Bearer ${refresh}`;
//           originalRequest.headers['Authorization'] = `Bearer ${refresh}`;

//           // Return the originalRequest to retry it with the new token
//           return axiosService(originalRequest);
//         }
//       } catch (refreshError) {
//         console.error("Unable to refresh token:", refreshError);
//         // store.dispatch(authSlice.actions.setLogout()); // Ensure logout on failure
//         return Promise.reject(refreshError); // Reject with the new error
//       }
//     }

//     // For errors other than 401 or if the refresh token process fails
//     return Promise.reject(error);
//   }
// );

// export default axiosService;
