import './PlantDetails.css';
import React, { useState, useEffect } from "react";
import Textarea from '@mui/joy/Textarea';

import ImageIcon from "../../assets/images/image.svg";




function PlantDetails() {

    const [estimatedClicked, setEstimatedClicked] = useState("estimated");



    return (
        <div className='container-padding'>
            <div className='row-sc gap5'>
                <span className='green29'>Dashboard</span>
                <span className='green85'>/</span>
                <span className='green29'>Inventory</span>
                <span className='green85'>/</span>
                <span className='green29'>Plants</span>
                <span className='green85'>/</span>
                <span className='green85'>001</span>
            </div>
            <div className='pd-base'>
                <div className='row-sbc'>
                    <div className='row-sc gap10'>
                        <img src={ImageIcon} />
                        <span className='green29'>001</span>
                    </div>

                    <img src={ImageIcon} />
                </div>

                <div className='row-sbs width100p gap20'>
                    <div className='pd-yield-cards'>
                        <div className='grey-icon-back'>
                            <img src={ImageIcon} />
                        </div>

                        <div className='column-cs'>
                            <span className='green29'>Total yield up to date of this strain</span>
                            <span className='green29'>1000g</span>
                        </div>
                    </div>

                    <div className='pd-yield-cards'>
                        <div className='grey-icon-back'>
                            <img src={ImageIcon} />
                        </div>

                        <div className='column-cs'>
                            <span className='green29'>Given out to members last month</span>
                            <span className='green29'>200g</span>
                        </div>
                    </div>

                </div>

                <div className='row-sbs width100p gap20'>
                    <div className='pd-detail-cards'>
                        <span className='green29'>Details</span>
                        <div className='row-sbc width100p'>
                            <span className='green29'>ID:</span>
                            <span className='green29'>001</span>
                        </div>

                        <div className='row-sbc width100p'>
                            <span className='green29'>Type:</span>
                            <span className='green29'>OG Kush</span>
                        </div>

                        <div className='row-sbc width100p'>
                            <span className='green29'>Grow Space:</span>
                            <span className='green29'>Room #1</span>
                        </div>

                        <div className='row-sbc width100p'>
                            <span className='green29'>Nutrient Receipe:</span>
                            <span className='green29'>???</span>
                        </div>

                        <span className='green29'>Long Description:</span>
                        <span className='green29'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</span>

                        <span className='green29'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</span>
                    </div>


                    <div className='pd-detail-cards'>
                        <span className='green29'>Attributes</span>
                        <div className='row-sbc width100p'>
                            <span className='green29'>Strain:</span>
                            <span className='green29'>OG Kush</span>
                        </div>

                        <div className='row-sbc width100p'>
                            <span className='green29'>Phase:</span>
                            <span className='green29'>Vegetative</span>
                        </div>

                        <div className='row-sbc width100p'>
                            <span className='green29'>Origin:</span>
                            <span className='green29'>Seed</span>
                        </div>

                        <div className='row-sbc width100p'>
                            <span className='green29'>Plant Type:</span>
                            <span className='green29'>Mother</span>
                        </div>

                        <div className='row-sbc width100p'>
                            <span className='green29'>Supplier:</span>
                            <span className='green29'>Supplier_A</span>
                        </div>

                        <div className='row-sbc width100p'>
                            <span className='green29'>Manufacturer:</span>
                            <span className='green29'>Manufacturer_A</span>
                        </div>


                        <div className='row-sbc width100p'>
                            <span className='green29'>Status:</span>
                            <span className='green29'>In Grow</span>
                        </div>

                    </div>


                    <div className='pd-detail-cards'>
                        <span className='green29'>Sample Titel</span>

                        <div className='row-sbs width100p'>

                            <div className='pd-estimate'
                                onClick={() => {
                                    setEstimatedClicked("estimated")
                                }}
                                style={{ borderBottom: estimatedClicked === "estimated" ? "2px solid #293D30" : "2px solid #E6E6E6" }}>
                                <span className='green29'>Estimated</span>
                                <div className='pd-number-back'>
                                    <span className='green29'>21</span>
                                </div>
                            </div>

                            <div className='pd-estimate'
                                onClick={() => {
                                    setEstimatedClicked("actual")
                                }}
                                style={{ borderBottom: estimatedClicked === "actual" ? "2px solid #293D30" : "2px solid #E6E6E6" }}>
                                <span className='green29'>Actual</span>
                                <div className='pd-number-back'>
                                    <span className='green29'>21</span>
                                </div>
                            </div>
                        </div>

                        {estimatedClicked === "estimated" ? (
                            <div className='pd-list'>

                                <div className='row-sbc width100p'>
                                    <span className='green29'>THC:</span>
                                    <span className='green29'>32%</span>
                                </div>

                                <div className='row-sbc width100p'>
                                    <span className='green29'>CBD:</span>
                                    <span className='green29'>20%</span>
                                </div>

                                <div className='row-sbc width100p'>
                                    <span className='green29'>Yield:</span>
                                    <span className='green29'>50g</span>
                                </div>

                                <div className='row-sbc width100p'>
                                    <span className='green29'>Grow Duration:</span>
                                    <span className='green29'>40 Days</span>
                                </div>

                                <div className='row-sbc width100p'>
                                    <span className='green29'>Plant Height:</span>
                                    <span className='green29'>100cm</span>
                                </div>

                                <div className='row-sbc width100p'>
                                    <span className='green29'>Turpines:</span>
                                    <span className='green29'>40g</span>
                                </div>
                            </div>
                        ) : (<div className='pd-list'>

                            <div className='row-sbc width100p'>
                                <span className='green29'>THC:</span>
                                <span className='green29'>100000%</span>
                            </div>

                            <div className='row-sbc width100p'>
                                <span className='green29'>CBD:</span>
                                <span className='green29'>51215%</span>
                            </div>

                            <div className='row-sbc width100p'>
                                <span className='green29'>Yield:</span>
                                <span className='green29'>00000.5g</span>
                            </div>

                            <div className='row-sbc width100p'>
                                <span className='green29'>Grow Duration:</span>
                                <span className='green29'>40 Days</span>
                            </div>

                            <div className='row-sbc width100p'>
                                <span className='green29'>Plant Height:</span>
                                <span className='green29'>100cm</span>
                            </div>

                            <div className='row-sbc width100p'>
                                <span className='green29'>Turpines:</span>
                                <span className='green29'>40g</span>
                            </div>
                        </div>)}
                    </div>
                </div>


                <div className='pd-notes'>
                    <span className='green29'>Notes</span>
                    <Textarea minRows={4}
                        className="pd-notes-input"
                        // color="#669977"
                        sx={{

                            '--Textarea-focusedThickness': '0.01rem',
                            '--Textarea-focusedHighlight': '#669977',

                            '&:focus-within': {
                                borderColor: '#669977',
                            },
                        }}
                    />

                    <div className='row-ss gap20'>
                        <span className='green29'>18 Dec 2023</span>
                        <div className='column-ss gap20'>
                            <span className='green29'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</span>
                            <span className='green29'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</span>
                        </div>
                    </div>
                </div>

            </div>
        </div >
    );
}

export default PlantDetails;