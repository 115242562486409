import "./Header.css";
import React from "react";
import Logo from "../../assets/images/homebrew.svg";
import Menu from "../../assets/images/menu.svg";
import ProfileIcon from "../../assets/images/profile.svg";
import Button from "@mui/material/Button";

function Header() {
  return (
    <div className="header-base">
      <div className="row-sc gap20">
        <img src={Menu} />
        <a href="/">
          <img src={Logo} />
        </a>
      </div>
      <div className="row-sc gap20">
        <a href="/signup">
          <Button color="success" className="btn btn-primary pointer">
            Sign Up
          </Button>
        </a>

        <a href="/login">
          <Button color="success" className="btn btn-primary pointer">
            Login
          </Button>
        </a>
        <img src={ProfileIcon} />
      </div>
    </div>
  );
}

export default Header;
