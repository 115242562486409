import "./Navigation.css";
import React, { useState, useEffect, useRef } from "react";
import DashboardIcon from "../../assets/images/dashboard.svg";
import CulticationIcon from "../../assets/images/cultivation.svg";
import BatchesIcon from "../../assets/images/batches.svg";
import GrowSpacesIcon from "../../assets/images/growspaces.svg";
import TaskManagementIcon from "../../assets/images/taskmanagement.svg";
import ToDoIcon from "../../assets/images/todolist.svg";
import TaskCaldendarIcon from "../../assets/images/taskcalendar.svg";
import CutonTasksIcon from "../../assets/images/customtask.svg";
import RecipesIcon from "../../assets/images/recipes.svg";
import EqipRecipesIcon from "../../assets/images/eqiprecipes.svg";
import TaskRecipesIcon from "../../assets/images/taskrecipes.svg";
import ManufacturingIcon from "../../assets/images/manufacturing.svg";
import InventoryIcon from "../../assets/images/inventory.svg";
import SalesIcon from "../../assets/images/sales.svg";
import AnalyticsIcon from "../../assets/images/analytics.svg";
import HelpIcon from "../../assets/images/help.svg";
import DropDownIcon from "../../assets/images/dropdown.svg";
import PlayIcon from "../../assets/images/play.svg";
import PlantIcon from "../../assets/images/plants.svg";
import SeedsIcon from "../../assets/images/seeds.svg";
import CloneIcon from "../../assets/images/copy.svg";
import DevicesIcon from "../../assets/images/devices.svg";

function Navigation() {
  const [rotateDrop1, setRotateDrop1] = useState(false);
  const [rotateDrop2, setRotateDrop2] = useState(false);
  const [rotateDrop3, setRotateDrop3] = useState(false);
  const [rotateDrop4, setRotateDrop4] = useState(false);
  const [rotateDrop5, setRotateDrop5] = useState(false);
  const [rotateDrop6, setRotateDrop6] = useState(false);
  const [rotateDrop7, setRotateDrop7] = useState(false);
  const [rotateDrop8, setRotateDrop8] = useState(false);
  const [rotateDrop9, setRotateDrop9] = useState(false);
  const [selectBatches, setSelectBatches] = useState(false);
  const [selectSeeds, setSelectSeeds] = useState(false);
  const [selectClones, setSelectClones] = useState(false);
  const [selectPlants, setSelectPlants] = useState(false);
  const [selectDevices, setSelectDevices] = useState(false);

  return (
    <div className="nav-base">
      <div className="new-run">
        <img src={PlayIcon} />
        <span className="white">Start New Run</span>
      </div>

      <a
        className="nav-link-drop"
        onClick={() => {
          setRotateDrop1(!rotateDrop1);
        }}
      >
        <div className="row-sc gap10 ">
          <img src={DashboardIcon} />
          <span className="green29">Dashboard</span>
        </div>
        <img src={DropDownIcon} style={{ rotate: rotateDrop1 && "180deg" }} />
      </a>

      <a
        className="nav-link-drop"
        onClick={() => {
          setRotateDrop2(!rotateDrop2);
        }}
        style={{ backgroundColor: rotateDrop2 && "#E6E6E6" }}
      >
        <div className="row-sc gap10 ">
          <img src={CulticationIcon} />
          <span className="green29">Cultivation</span>
        </div>
        <img src={DropDownIcon} style={{ rotate: rotateDrop2 && "180deg" }} />
      </a>
      {rotateDrop2 ? (
        <div className="drop-pad">
          <a
            className="nav-link"
            href="/batches"
            onClick={() => {
              setSelectBatches(!selectBatches);
            }}
            style={{ backgroundColor: selectBatches && "#E6E6E6" }}
          >
            <img src={BatchesIcon} />
            <span className="green29">Batches</span>
          </a>

          <a className="nav-link" href="/grow_spaces">
            <img src={GrowSpacesIcon} />
            <span className="green29">Grow Spaces</span>
          </a>

          <a
            className="nav-link-drop"
            onClick={() => {
              setRotateDrop3(!rotateDrop3);
            }}
            style={{ backgroundColor: rotateDrop3 && "#E6E6E6" }}
          >
            <div className="row-sc gap10 ">
              <img src={TaskManagementIcon} />
              <span className="green29">Task Management</span>
            </div>
            <img
              src={DropDownIcon}
              style={{ rotate: rotateDrop3 && "180deg" }}
            />
          </a>

          {rotateDrop3 ? (
            <div className="drop-pad">
              <a className="nav-link" href="/to_do_list">
                <img src={ToDoIcon} />
                <span className="green29">To-Do List</span>
              </a>
              <a className="nav-link">
                <img src={TaskCaldendarIcon} />
                <span className="green29">Task Calendar</span>
              </a>
              <a className="nav-link">
                <img src={CutonTasksIcon} />
                <span className="green29">Custom Tasks</span>
              </a>

              <a
                className="nav-link-drop"
                onClick={() => {
                  setRotateDrop4(!rotateDrop4);
                }}
              >
                <div className="row-sc gap10 ">
                  <img src={RecipesIcon} />
                  <span className="green29">Recepies</span>
                </div>
                <img
                  src={DropDownIcon}
                  style={{ rotate: rotateDrop4 && "180deg" }}
                />
              </a>

              {rotateDrop4 ? (
                <div className="drop-pad">
                  <a className="nav-link">
                    <img src={EqipRecipesIcon} />
                    <span className="green29">Eqipment Recipes</span>
                  </a>
                  <a className="nav-link">
                    <img src={TaskRecipesIcon} />
                    <span className="green29">Task Recipes</span>
                  </a>
                </div>
              ) : (
                <div></div>
              )}
            </div>
          ) : (
            <div></div>
          )}
        </div>
      ) : (
        <div></div>
      )}

      <a
        className="nav-link-drop"
        onClick={() => {
          setRotateDrop5(!rotateDrop5);
        }}
      >
        <div className="row-sc gap10 ">
          <img src={ManufacturingIcon} />
          <span className="green29">Manufacturing</span>
        </div>
        <img src={DropDownIcon} style={{ rotate: rotateDrop5 && "180deg" }} />
      </a>

      <a
        className="nav-link-drop"
        onClick={() => {
          setRotateDrop6(!rotateDrop6);
        }}
      >
        <div className="row-sc gap10 ">
          <img src={InventoryIcon} />
          <span className="green29">Inventory</span>
        </div>
        <img src={DropDownIcon} style={{ rotate: rotateDrop6 && "180deg" }} />
      </a>

      {rotateDrop6 ? (
        <div className="drop-pad">
          <a
            className="nav-link"
            href="/seeds"
            onClick={() => {
              setSelectSeeds(!selectSeeds);
            }}
            style={{ backgroundColor: selectSeeds && "#E6E6E6" }}
          >
            <img src={SeedsIcon} />
            <span className="green29">Seeds</span>
          </a>

          <a
            className="nav-link"
            href="/clones"
            onClick={() => {
              setSelectClones(!selectClones);
            }}
            style={{ backgroundColor: selectClones && "#E6E6E6" }}
          >
            <img src={CloneIcon} />
            <span className="green29">Clones</span>
          </a>

          <a
            className="nav-link"
            href="/plants"
            onClick={() => {
              setSelectPlants(!selectPlants);
            }}
            style={{ backgroundColor: selectPlants && "#E6E6E6" }}
          >
            <img src={PlantIcon} />
            <span className="green29">Plants</span>
          </a>

          <a
            className="nav-link"
            href="/devices"
            onClick={() => {
              setSelectDevices(!selectDevices);
            }}
            style={{ backgroundColor: selectDevices && "#E6E6E6" }}
          >
            <img src={DevicesIcon} />
            <span className="green29">Devices</span>
          </a>
        </div>
      ) : (
        <div></div>
      )}

      <a
        className="nav-link-drop"
        onClick={() => {
          setRotateDrop7(!rotateDrop7);
        }}
      >
        <div className="row-sc gap10 ">
          <img src={SalesIcon} />
          <span className="green29">Sales</span>
        </div>
        <img src={DropDownIcon} style={{ rotate: rotateDrop7 && "180deg" }} />
      </a>

      <a
        className="nav-link-drop"
        onClick={() => {
          setRotateDrop8(!rotateDrop8);
        }}
      >
        <div className="row-sc gap10 ">
          <img src={AnalyticsIcon} />
          <span className="green29">Analytics</span>
        </div>
        <img src={DropDownIcon} style={{ rotate: rotateDrop8 && "180deg" }} />
      </a>

      <a className="nav-link-drop">
        <div
          className="row-sc gap10"
          onClick={() => {
            setRotateDrop9(!rotateDrop9);
          }}
        >
          <img src={HelpIcon} />
          <span className="green29">Help</span>
        </div>
        <img src={DropDownIcon} style={{ rotate: rotateDrop9 && "180deg" }} />
      </a>
    </div>
  );
}

export default Navigation;
